/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import listItem from '@/components/common/ListItem';
import { Button, ButtonGroup } from 'vue-ydui/dist/lib.rem/button';
import router from '@/router/index';
import { CheckBox, CheckBoxGroup } from 'vue-ydui/dist/lib.rem/checkbox';
Vue.component(CheckBox.name, CheckBox);
Vue.component(CheckBoxGroup.name, CheckBoxGroup);
Vue.component(Button.name, Button);
Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(listItem.name, listItem);
export default {
  data() {
    return {
      mailList: [],
      userFolderArray: [],
      mailFolderData: {
        recieveFolderId: "",
        sendMailFolderId: "",
        draftFolderId: "",
        recyclFolderId: ""
      }
    };
  },
  props: {
    listData: String
  },
  methods: {
    getDraftMailList() {
      var url = _baseUrl + "/mobile/oa/get" + this.listData + "List.do";
      this.$ajax({
        url: url,
        method: 'POST'
      }).then(response => {
        this.mailList = response.data.data;
      }).catch(error => {
        console.log(error);
      });
    },
    getUserMailFolder() {
      let storeFolderData = this.$store.state.mailModule.folderData;
      //缓存已有数据			
      if (storeFolderData[0] != null) {
        this.mailFolderData = storeFolderData;
        return;
      }
      var url = _baseUrl + "/mobile/oa/getUserMailFolder.do";
      this.$ajax({
        url: url,
        method: 'POST'
      }).then(response => {
        this.userFolderArray = response.data.data;
        //处理用户文件夹数据
        this.handUserFolder(this.userFolderArray);
        //把数据存入vuex进行状态管理
        this.$store.commit('setFolderData', this.mailFolderData);
        this.getDraftMailList();
      }).catch(error => {
        console.log(error);
      });
    },
    handUserFolder(userFolder) {
      if (userFolder == null) {
        return;
      }
      for (let i = 0; i < userFolder.length; i++) {
        switch (userFolder[i].type) {
          case 'RECEIVE-FOLDER':
            this.mailFolderData.recieveFolderId = userFolder[i].folderId;
            break;
          case 'SENDER-FOLDER':
            this.mailFolderData.sendMailFolderId = userFolder[i].folderId;
            break;
          case 'DRAFT-FOLDER':
            this.mailFolderData.draftFolderId = userFolder[i].folderId;
            break;
          case 'DEL-FOLDER':
            this.mailFolderData.recyclFolderId = userFolder[i].folderId;
            break;
          default:
            break;
        }
      }
    },
    getFirstLetter(word) {
      return word.substr(0, 1);
    }
  },
  created() {
    this.getUserMailFolder();
  },
  components: {}
};