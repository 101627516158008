/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import listItem from '@/components/common/ListItem';
import { Button, ButtonGroup } from 'vue-ydui/dist/lib.rem/button';
import router from '@/router/index';
import { CheckBox, CheckBoxGroup } from 'vue-ydui/dist/lib.rem/checkbox';
import GroupTitle from "vux/src/components/group-title/index.vue";
import Swipeout from "vux/src/components/swipeout/swipeout.vue";
import SwipeoutItem from "vux/src/components/swipeout/swipeout-item.vue";
import SwipeoutButton from "vux/src/components/swipeout/swipeout-button.vue";
import XButton from "vux/src/components/x-button/index.vue";
Vue.component(CheckBox.name, CheckBox);
Vue.component(CheckBoxGroup.name, CheckBoxGroup);
Vue.component(Button.name, Button);
Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(listItem.name, listItem);
export default {
  data() {
    return {
      mailList: [],
      userFolderArray: [],
      mailFolderData: {
        recieveFolderId: "",
        sendMailFolderId: "",
        draftFolderId: "",
        recyclFolderId: ""
      }
    };
  },
  props: {
    listData: String
  },
  methods: {
    getRecieveMailList() {
      var url = _baseUrl + "/mobile/oa/getInnerMailByFolderId.do";
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          folderId: this.mailFolderData[this.listData]
        }
      }).then(response => {
        this.mailList = response.data.data;
      }).catch(error => {
        console.log(error);
      });
    },
    getUserMailFolder() {
      let storeFolderData = this.$store.state.mailModule.folderData;
      //缓存已有数据			
      if (storeFolderData[0] != null) {
        this.mailFolderData = storeFolderData;
        return;
      }
      var url = _baseUrl + "/mobile/oa/getUserMailFolder.do";
      this.$ajax({
        url: url,
        method: 'POST'
      }).then(response => {
        this.userFolderArray = response.data.data;
        //处理用户文件夹数据
        this.handUserFolder(this.userFolderArray);
        //把数据存入vuex进行状态管理
        this.$store.commit('setFolderData', this.mailFolderData);
        this.getRecieveMailList();
      }).catch(error => {
        console.log(error);
      });
    },
    handUserFolder(userFolder) {
      if (userFolder == null) {
        return;
      }
      for (let i = 0; i < userFolder.length; i++) {
        switch (userFolder[i].type) {
          case 'RECEIVE-FOLDER':
            this.mailFolderData.recieveFolderId = userFolder[i].folderId;
            break;
          case 'SENDER-FOLDER':
            this.mailFolderData.sendMailFolderId = userFolder[i].folderId;
            break;
          case 'DRAFT-FOLDER':
            this.mailFolderData.draftFolderId = userFolder[i].folderId;
            break;
          case 'DEL-FOLDER':
            this.mailFolderData.recyclFolderId = userFolder[i].folderId;
            break;
          default:
            break;
        }
      }
    },
    getAbstract(mailContent) {
      if (mailContent.length == 0 || mailContent == "无") {
        return "（无摘要）";
      } else if (mailContent.length >= 30) {
        return mailContent.substr(0, 30) + "...";
      } else {
        return mailContent;
      }
    },
    deleteMail(mail, mailType) {
      var url = _baseUrl + "/mobile/oa/moveInnerMailToFolder.do";
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          target: "del",
          ids: mail.mailId,
          nowFolderId: this.mailFolderData.sendMailFolderId
        }
      }).then(response => {
        if (response.data.success) {
          this.successToast("邮件丢进垃圾箱了", 1000);
        }
        if (mailType == 'recieve') {
          this.getRecieveMailList();
        } else if (mailType == 'sendMail') {
          this.getSendedMailList();
        } else if (mailType == 'draft') {
          oldFolderId = this.mailFolderData.draftFolderId;
        } else {
          oldFolderId = this.mailFolderData.recyclFolderId;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getMailDetail(mail, mailType) {
      let oldFolderId = "";
      if (mailType == 'recieve') {
        oldFolderId = this.mailFolderData.recieveFolderId;
      } else if (mailType == 'sendMail') {
        oldFolderId = this.mailFolderData.sendMailFolderId;
      } else if (mailType == 'draft') {
        oldFolderId = this.mailFolderData.draftFolderId;
      } else {
        oldFolderId = this.mailFolderData.recyclFolderId;
      }
      router.push({
        name: 'innerMailDetail',
        params: {
          mail: mail,
          mailType: mailType,
          oldFolderId: oldFolderId
        }
      });
    },
    getFirstLetter(word) {
      return word.substr(0, 1);
    }
  },
  created() {
    this.getUserMailFolder();
  },
  components: {
    Swipeout,
    SwipeoutItem,
    SwipeoutButton,
    XButton
  }
};