/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Move from '@/components/Move';
import MailList from '@/components/oa/MailList';
import MailTemporary from '@/components/oa/MailTemporary';
export default {
  data() {
    return {
      dateList: {
        icon: {
          1: "recieve",
          2: "sendMail",
          3: "draft",
          4: "recycl"
        },
        title: {
          1: "收件箱",
          2: "发件箱",
          3: "草稿箱",
          4: "垃圾箱"
        }
      }
    };
  },
  methods: {
    sendMailFunc() {
      this.$router.push({
        name: 'sendMail',
        params: '{mailType:mailType}'
      });
    }
  },
  created() {},
  components: {
    Move,
    MailList,
    MailTemporary
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$refs.move.obtainIndex(to.fullPath);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.layer > from.meta.layer) {
      this.$refs.move.keepIndex(from.fullPath);
    } else {
      this.$refs.move.keepIndex(from.fullPath, "empty");
    }
    next();
  }
};